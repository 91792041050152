.page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - var(--height_of_menu));
  margin: 0 3vh;
  padding: 3vh 0;
  max-width:90%;
}

.box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  max-width: 100%;
  width: 1000px;
  background-color: white;
  box-shadow: 0 0 40px rgba(0,0,0,0.16);
  overflow: hidden;
  margin: 0 auto;
  border-radius: 12px;
}

.form {
  flex: 1 0 100%;
  max-width: 480px;
  width: 100%;
  padding: 30px;
}

.formTitle {
  color: #333333;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 42px;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 0;
}

.formUsername {
  height: 48px;
}


.formButton {
  width: 100%;
}

.illustrationWrapper {
  display: flex;
  align-items: flex-end;
  max-width: 500px;
  min-height: 100%;
  background-color: #fffdf2;
}

.illustrationWrapper img {
  display: block;
  width: 100%;
}

@media screen and (max-width: 850px) {
  .form {
    max-width: 350px;
  }
}

@media screen and (max-width: 700px) {
  .box {
    flex-direction: column;
    box-shadow: none;
  }
  .illustrationWrapper {
    max-width: 100%;
    min-height: auto;
    display: none;
  }
  .form {
    max-width: 100%;
  }
}

.codeInput {
  width: 100%;
  height: 30px;
  font-size: 20px;
  text-align: center;
}

.resendLink {
  color: var(--link);
  cursor: pointer;
}
