.holderbanner{

}

.banner{
    max-width:100%;
}

.holderListMembers{
    
}