.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #ffffff;
  z-index: 1000;
}

.preloader .spinner {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
}

.preloader .spinner .double-bounce1,
.preloader .spinner .double-bounce2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #78cc6d;
  opacity: 1;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -khtml-border-radius: 50%;
}

.preloader .spinner .double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
  opacity: 0.1;
}

/* preloader */
@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }

  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
