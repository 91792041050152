.page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - var(--height_of_menu));
  margin: 0 3vh;
  padding: 3vh 0;
  max-width: 100%;
}
.box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  max-width: 1000px;
  background-color: white;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  margin: 0 auto;
  border-radius: 12px;
}

.notFoundContainer {
  text-align: center;
  margin-top: 50px;
  padding: 50px;
}

.notFoundMessage {
  font-size: 24px;
  color: #555;
}
