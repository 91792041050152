.mainHolder {
  height: calc(90vh - var(--height_of_menu));
  max-height: 674px;
  position: relative;
  top: 0;
  left: 0;
}

.container {
  background: linear-gradient(
    to bottom right,
    #e5e6e8 0%,
    var(--background) 100%
  );
  color: var(--font);
  height: calc(100vh - var(--height_of_menu));
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner {
  height: 100%;
  width: 480px;
  background-color: var(--background_second);
  position: relative;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.05);
  z-index: 20;
}
.bannerDetails {
  overflow: hidden;
  height: 100%;
  width: 100%;
  z-index: 10;
  position: relative;
}
.bannerBackground {
  background: linear-gradient(
    135deg,
    rgba(76, 119, 83, 0.4) 0%,
    rgba(76, 119, 83, 0.01) 100%
  );
  content: "";
  position: absolute;
  left: -15px;
  top: -15px;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.card {
  width: auto;
  height: 100%;
  top: 0;
  left: 480px;
  right: 0;
  /* background-color: pink; */
  position: absolute;
  padding: 10px 0;
}

.EachPartOFMenu {
  height: 100%;
  background: var(--background_second);
  width: 100%;
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
  position: absolute;
  left: -480px;
  opacity: 0;
  z-index: 5;
  top: 0;
  animation: fadeIn 1s forwards;
  transition: left 1s ease-out, opacity 1s ease-out;
  overflow: auto;
  padding: 20px;
}

.EachPartOFMenuSelected {
  left: 0;
  opacity: 1;
  z-index: 10;
}

.titleCard {
}

.cardRealtive {
  position: relative;
  width: 100%;
  height: 100%;
}

.profileImg {
  height: 50%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.profileImg::before {
  left: -25%;
  bottom: -63%;
  width: 100%;
  height: 70%;
  transform: rotate(12deg);
  -webkit-transform: rotate(12deg);
  -moz-transform: rotate(12deg);
  -o-transform: rotate(12deg);
}

.profileImg::after {
  left: auto;
  bottom: -63%;
  right: -25%;
  width: 100%;
  height: 70%;
  transform: rotate(-12deg);
  -webkit-transform: rotate(-12deg);
  -moz-transform: rotate(-12deg);
  -o-transform: rotate(-12deg);
}

.profileImg::before,
.profileImg::after {
  content: "";
  position: absolute;
  width: 700px;
  height: 200px;
  background: var(--background_second);
}

.holderNameAndJob {
  height: 50%;
  top: 50%;
  background-color: var(--background_second);
}

.name {
  font-size: 34px;
  color: var(--font_second);
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.specialties {
  color: var(--primary-color);
  font-size: 20px;
  line-height: 20px;
  height: 20px;
  font-weight: 400;
}

.holderRealativeNameAndJob {
  position: relative;
  width: 100%;
  height: 100%;
}



.holderSocialMedia * {
  color: var(--font_second) !important;
  font-weight: normal;
}

.holderDownload {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  color: var(--font_second);
}
.holderDownload:hover,
.holderDownload:hover * {
  cursor: pointer;
  color: var(--primary-color);
}

.holderDownload::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 1px;
  background: radial-gradient(
    ellipse at center,
    var(--border) 0%,
    rgba(255, 255, 255, 0) 70%
  );
}

.holdermenu {
  width: 72px;
  height: auto;
  position: absolute;
  left: -80px;
  top: 10px;
  min-height: 50px;
  background-color: var(--background_second);
  z-index: 30;
}

.btnMenu {
  width: 72px;
  height: 72px;
  position: relative;
  cursor: pointer;
}
.btnMenu * {
  cursor: pointer;
}
.iconBtnMenu {
  font-size: 21px;
  margin: 0.4rem !important ;
  border-bottom: 1px solid var(--border);
}

.titleBtnMenu {
  font-size: 12px;
}

.btnMenu::before {
  background: radial-gradient(
    ellipse at right,
    #dddddd 0%,
    rgba(255, 255, 255, 0) 70%
  );
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
}

.btnMenu.selected * {
  color: var(--primary-color) !important;
  font-weight: bold;
}

@media (max-width: 800px) {
  .mainHolder {
    max-width: calc(100% - 105px);
    height: auto;
    max-height: unset;
    margin-left: 90px;
  }

  .card {
    height: auto;
    width: 100%;
    position: relative;
    left: 0;
  }

  .banner {
    /* display: none; */
    width: 100%;
    margin-top: 13px;
  }
  .holderRealativeNameAndJob {
    height: auto;
  }
  .holderTextInNameAndJob {
    transform: unset;
    padding-bottom: 10px;
  }
  .bannerBackground {
    left: -5px;
    top: -5px;
  }
  .profileImg {
    height: 38vw;
  }
  .profileImg::before {
    display: none;
  }

  .profileImg::after {
    display: none;
  }

  .EachPartOFMenu {
    position: relative;
    top: unset;
    left: unset;
    height: auto;
    opacity: 1;
    overflow: visible;
  }
  .container {
    display: unset;
  }

  .holdermenu {
    position: fixed;
    left: 10px;
    top: 65px;
  }
  .holderDownload {
    position: relative;
  }
}

@media (min-width: 800px) and (max-width: 1268px) {
  .mainHolder {
    max-width: calc(100% - 105px);
    height: auto;
    max-height: unset;
    margin-left: 90px;
  }

  .card {
    height: auto;
    width: 100%;
    position: relative;
    left: 0;
  }

  .banner {
    /* display: none; */
    width: 100%;
    margin-top: 13px;
  }
  .holderNameAndJob {
    height: auto;
    width: 60%;
  }
  .holderRealativeNameAndJob {
    height: 220px;
  }
  .holderTextInNameAndJob {
    transform: unset;
  }
  .bannerBackground {
    display: none;
  }

  .bannerDetails {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .profileImg {
    width: 40%;
    height: auto;
    padding-top: 40%;
  }
  .profileImg::before {
    display: none;
  }

  .profileImg::after {
    display: none;
  }

  .EachPartOFMenu {
    position: relative;
    top: unset;
    left: unset;
    height: auto;
    opacity: 1;
    overflow: visible;
  }
  .container {
    display: unset;
  }

  .holdermenu {
    position: fixed;
    left: 10px;
    top: 65px;
  }

  .holderDownload {
    position: relative;
  }
}
