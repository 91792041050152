.fadeInSection {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 0.6s ease-out,
    visibility 0.6s ease-out;
  will-change: opacity, visibility, transform;
}

.isVisible {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}

.holdeEachBox {
  width: 100%;
}
.experienceTitle:hover {
  color: var(--link) !important;
}

.holderImg {
  width: 100%;
  overflow: hidden;
  position: relative;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0px -6px 14px 0px #000 !important;
}

.holderImg::after {
  display: block;
  padding-top: 100%;
  content: " ";
  overflow: hidden;
}

.experienceCard {
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 1rem;
  margin-bottom: 1rem;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  height: 100%;
}

.experienceCard:hover {
  transform: scale(1.05);
}

.experienceDetails {
  padding: 0.5rem;
  background: white;
  text-align: center;
}
.experienceTitleLink {
  text-decoration: none !important;
}
.experienceTitle {
  margin: 0.5rem 0;
  margin-top: 0;
  color: #333;
  text-decoration: none !important;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-weight: bold;
}

.experienceSummary {
  font-size: 0.9rem;
  color: #666;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.experienceSummary::before {
  content: "\00a0"; /* This is a non-breaking space character */
}

.showMoreButton {
  display: inline-block;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.5rem 1rem;
  color: white;
  background-color: var(--primary-color);
  border-radius: 20px;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
}

.showMoreButton:hover {
  background-color: var(--primary-color-hover);
}

.eachExperience {
  display: flex;
  flex-direction: column; /* This stacks the child elements (image and details) vertically */
  justify-content: space-between;
}
